<template>
  <div class="dairy-wrapper">
    <p-header></p-header>
    <div class="dairy-container">
      <img src="~common/images/case_top.png" width="100%" alt="">

      <div class="index-container" v-show="detailIndex==1">
        <div class="detail-box">
          <img src="~common/images/index_case_ic.png" alt="" class="case-ic">
          <div class="title">促销引流两不误，传统营销玩法<br/>如何重回第一梯队帮助品牌快速动销？</div>
          <div class="content">2021年，国内快消品市场终于挨过了黑天鹅风波，进入了“重建”时期。消费者重新回归线下门店，如何在这段市场复苏快速上升的时期抓住机会，快速提升销量抢占终端铺市率？某乳酸菌饮品通过结合一物一码技术，打造“揭盖有奖 壹元换购活动”，给出了答案。</div>
          <div class="content">在2021年初某乳酸菌饮品开展的“揭盖有奖，1元换购”活动中，品牌将传统的“壹元换购”营销活动与一物一码结合进行数字化升级。同时，通过搭建品牌小程序，将活动流量沉淀到品牌私域，并通过不断的线上运营来刺激复购。活动开展至今，已经为品牌带来了较为明显的销量增长，渠道铺市率大幅提升。</div>
          <div class="content">那么，品牌在这次的营销活动中，都做对了什么呢？</div>

          <div class="subtitle"><div class="subcontent">1、数字化赋能核销链路，壹元换购快速动销</div></div>
          <div class="content">放眼快消品行业的市场情况，虽然线上渠道蓬勃发展，但根据凯度报告，截至2020年Q3电商渠道销量占比其实只有21%，终端门店仍然是最重要的消费场景。品牌经受了冲击的线下营销渠道，需要一剂“猛药”来将销量提升上去。</div>
          <div class="content">该乳酸菌饮品选择了“壹元换购”。细数快消品的各类营销玩法，“壹元换购”这类营销活动曾备受饮品追捧；消费者购买产品后，凭借中奖瓶盖花一元钱就可以购买一瓶同样的产品。这种营销玩法，消费者喜闻乐见，促销效果明显；终端门店提升销量又得实惠，配合度高。</div>
          <div class="content">然而问题却也很明显。</div>
          <div class="content">长久以来，快消品品牌触达终端门店的方式只能依赖层层分级的经销体系，靠人力下沉完成。这导致品牌在做诸如“壹元换购”，这种需要经销体系不同层级配合的营销活动时，面临着造假多、监管难等诸多难点。</div>
          <div class="content">传统的壹元换购活动中，经销商和品牌间的核销往往需要通过数瓶盖或称瓶盖来实现，这种需要人力手工完成的传统核销方式引发了诸多问题，比如：</div>
          <div class="content">以称重来确认瓶盖数量所造成的核销数量不精准；</div>
          <div class="content">核销瓶盖过程中耗费的大量人力、运输成本；</div>
          <div class="content">甚至在渠道链路核销过程中，别有用心的人伪造印有中奖标识瓶盖来向品牌骗取返点；</div>
          <div class="content">......</div>
          <div class="content">最终都会导致产生较高的渠道费用损耗。特别是对于头部品牌来说，这种线下门店高达几十万家的全国性品牌，开展传统的一元换购活动所投入的精力、成本往往是巨大的。</div>
          <div class="content">这也是为什么反观近几年，这类瓶盖兑换活动总是让品牌“又爱又恨”</div>

          <img src="~common/images/index_case_ic.png" alt="" class="case-ic">
          <div class="title">那么这次，我们如何让“壹元换购”<br/>重回营销玩法第一梯队呢？</div>
          <div class="content">面对传统核销方式所造成的诸多问题，通过我们对快消品渠道特点的了解，以及结合对一物一码技术的研究为该品牌量身打造了一套数字化的壹元换购解决方案。</div>
          <div class="content">通过在瓶盖内附加一物一码，数码通将整个核销流程进行数字化打通。消费者扫描中奖瓶盖内的二维码获取兑奖凭证兑换商品；经销商通过扫码核销终端回收的中奖瓶盖形成核销记录；而品牌商只需要通过数据后台查看核销情况、调控活动的区域及力度，并做好核销过程中的激励就好。</div>
          <div class="content">通过一物一码为传统的核销方式进行数字化赋能，极大减轻了品牌开展壹元换购活动过程中的管理成本。同时，核销链路的数据打通结合一物一码的防伪功能，有效得抑制了核销过程中的造假问题。据品牌反馈，本次活动至今未发现任何核销数量造假造假的情况，为品牌大幅减少了损失。</div>

          <div class="subtitle"><div class="subcontent">2、产品做触点，引流沉淀私域</div></div>
          <div class="content">另外，除了抑制造假、“降本增效”以外，结合一物一码开展壹元换购的另一个好处便是使品牌沉淀消费者私域流量成为可能。</div>
          <div class="content">私域流量这个词近两三年的兴起，标志着品牌砸钱打广告但看不见明显效果的日子正在成为过去式。品牌营销活动做了，除了要能看到效果，“留下了什么”开始变得越来越重要。我们意识到，通过瓶盖内附加一物一码，该品牌本次壹元换购活动除了优化线下核销流程以外，同时也为产品本身赋能，将产品变成了品牌触达消费者的最直接的“触点”。</div>
          <div class="content">于是，在开展营销活动的同时沉淀品牌私域流量的想法应运而生。</div>
          <div class="content">消费者扫码获取壹元换购兑换凭证的同时，将跳转到品牌微信小程序。以小程序作为品牌的线上流量阵地，将现实中的线下消费者引导至线上，并通过不断运营沉淀为品牌自身私域流量池，实现品牌与消费者持续的互动。</div>

           <img src="~common/images/index_case_ic.png" alt="" class="case-ic">
          <div class="title">“周周抽锦鲤”<br/>刺激复购、裂变</div>
          <div class="content">在小程序中，我们为品牌设计了“周周抽锦鲤”抽奖活动。每周，小程序用户都可以通过完成两个“锦鲤任务”解锁抽取锦鲤大奖的机会，任务包括购买产品扫码，以及将活动分享给好友。通过该抽奖活动不断刺激用户复购，对活动行程自发传播裂变。</div>
          <div class="content">同时，我们在小程序中融入“活力值“积分体系，并与品牌线上商城进行打通。消费者可以通过诸如线上游戏、运动打卡、每日签到等活动获取活力值，并兑换优惠券等权益，跳转线上商城形成购买。以此沉淀品牌的私域流量池，不断运营形成复购。</div>

          <div class="subtitle"><div class="subcontent">3、数字化技术铺路，着眼长效ROI</div></div>
          <div class="content">迈入2021年，品牌凭借数字化壹元换购新玩法迎来了开门红。更重要的是，活动的高参与度为品牌带来了线上流量。借助一物一码的数据采集能力，这些流量将转化为重要的数据资产，帮助品牌不断优化自己的营销策略。通过一次壹元换购营销活动，品牌就已经将促销、引流、沉淀、运营、裂变复购的整条营销通路捋顺。帮助品牌以更年轻态的方式与消费者互动沟通，以更多元化的方式沉淀私域流量、促进转发复购。为品牌后续开展营销活动，触达消费者奠定了基础。</div>

        </div>
      </div>

      <div class="index-container" v-show="detailIndex==2">
        <div class="detail-box">
          <img src="~common/images/index_case_ic.png" alt="" class="case-ic">
          <div class="title">某鲜奶品牌节日促销做对了哪些事？</div>
          <div class="content">2020年，国内市场饱受冲击。下半年形势稍缓，正值中秋、国庆双节的来临，快消品品牌都想借助这个关键时间点打一场漂亮的翻身仗。就在这个时间节点上，借助数码通独家核销系统，某鲜奶品牌上线了“迎中秋，贺国庆！喝XX鲜语，开瓶盖赢再来一瓶”活动。</div>
          <div class="content">本次活动历时约2个月左右，通过对一物一码的应用，将传统的“再来一瓶”营销活动进行数字化升级，并与消费者线上DIY互动相结合。在没有太多推广投放的情况下，达成了“百万级扫码、互动参与量”这样不错的成绩，并最终获得了品牌的好评。</div>
          <div class="content">那么接下来，品牌开展节日促销都做对了什么呢？</div>

          <div class="subtitle"><div class="subcontent">1、一物一码赋能线下渠道核销，营销数字化更“踏实”</div></div>
          <div class="content">数字化技术的发展应用加之对“宅经济”的催化，导致了2020年线上渠道的蓬勃发展。但放眼快消品行业的市场情况，线上占比只有14%，终端门店仍然是最重要的消费场景。但是，长久以来快消品品牌触达终端门店的方式只能依赖层层分级的经销体系，靠人力下沉完成。这导致品牌在做诸如“再来一瓶”，这种需要经销体系不同层级配合的营销活动时，面临着造假多、监管难等诸多难点。品牌每年投入的渠道费用中，将产生20%-30%，甚至高达50%的费用损耗。</div>
          <div class="content">面对这样的问题，数码通应用一物一码技术，将渠道核销流程进行了全面数字化、信息化的打通。消费者购买产品扫码参与活动，到终端门店扫码完成核销，经销商协助门店注册生成一店一码，品牌和经销商通过后台记录的核销数据完成最终核销和活动管理。整条核销链路围绕一物一码全面在线化，有效得解决了造假多、监管难、损耗大等种种昔日的“痛点”，为品牌商大量降低开展促销活动的成本。消费者兑换奖品更便捷，活动体验更好。</div>

          <div class="subtitle"><div class="subcontent">2、用互动连接品牌和消费者，营销更“交心”</div></div>
          <div class="content">大环境的冲击，让品牌做营销变得更加谨慎，也更加注重“品效合一”。这次中秋国庆双节活动，品牌不想仅仅将它打造成追求销量的促销活动，而是希望品牌可以借此机会能与消费者产生更多的互动，更“交心”。</div>
          <div class="content">于是，线上创意PK赛应运而生。消费者在扫码参与“再来一瓶”的过程中，获得兑换机会后将跳转到品牌活动线上小程序。通过上传自己和品牌的创意照片、视频等作品参与投票评选，或为其他作品投票。</div>
          <div class="content">作为一款主打“新鲜、高端”的高品质鲜奶，品牌的用户一直定位在高端精致人群，特别是新知女性和精英中产。当我们在思考如何与以这部分群体为主的用户群互动时，我们认为“分享”这一概念对于广大当代中产阶层，特别是新知女性是具有很强的吸引力的。“通过分享品牌，进而分享自己高情调的品质生活”这一活动主题本身也与品牌的整体调性相契合。</div>
          <div class="content">搭配上以品牌产品本身为主的奖品激励，创意PK赛一经上线就收获了不错的效果。上百万的活动访问次数以及上万作品上传数量。在消费者积极响应参与的过程中，达到了通过活动激励用户自发传播裂变，向消费者传递品牌形象、品牌理念的目的。</div>
        </div>
      </div>

      <div class="index-container" v-show="detailIndex==3">
        <div class="detail-box">
          <img src="~common/images/index_case_ic.png" alt="" class="case-ic">
          <div class="title">品牌营销拒绝内卷，看啤酒品牌<br/>如何“一举多得”</div>
          <div class="content">自2014年某饮用水品牌率先启用“一物一码”技术，将二维码应用到营销领域至今，通过产品包装赋码进行“营销数字化”，已经被行业讨论了8年。这一路走来，二维码营销的玩法花样百出、各有千秋，涌现了无数另人拍案叫绝的惊艳案例。</div>
          <div class="content">“消费者扫码”是比较简单且常见的一物一码/二维码赋码玩法。这类玩法在某品牌开始做的时候，可谓是对传统营销“套路”的一次突破，无论品牌和消费者一时都觉得很新鲜。但如今再走入商超，货架上琳琅满目的品牌很多都有扫码抽奖的活动；部分食品品牌哪怕还没有亲自尝试过，也没少看过“猪跑”，而消费者更是早已“见怪不怪”。</div>
          <div class="content">现如今，品牌在面对这类活动的时候，如今要打上一个大大的问号：“我的产品上加了码，到底都能给我带来什么收益？竞品们都是扫码百分百得0.3元，我的奖金是不是必须翻10倍才能吸引到消费者？砸不起钱，搞这种活动还有没有意义？……”</div>
   
          <div class="content">其实我们想说，是扫码这类活动的同质化过于严重，有点“过时”了。在如今注重“品销合一”、注重“数据价值”的发展趋势下，扫码类活动的固定玩法已经是“5无产品”了。</div>
          <div class="content">无变化：要么大转盘要么九宫格，多年来一成不变的互动玩法，消费者早已“审美疲劳”；</div>
          <div class="content">无销售：几毛钱有点小“寒酸”，想靠“小恩小惠”带动产品销量，消费者并不买账；</div>
          <div class="content">无连接：消费者扫码领取奖励后转头就说拜拜，与品牌没有产生任何粘性；</div>
          <div class="content">无感知：扫码奖励家家送，参与扫码的消费者领完内心甚至“毫无波澜”；</div>
          <div class="content">无数据：“雁过不留痕”，消费者参与互动后品牌无法积累任何数据资产。</div>
          <div class="content">既然有点过时了，那这扫码活动，品牌还要玩吗？</div>
          <div class="content">当然要玩！在数字化的大潮下，包装赋码是将产品变成线下流量触点的不二法门，任何错过这部分海量流量的品牌都是对品牌自身拥有资产的极大浪费！</div>
          <div class="content">那，怎么玩？</div>
          <img src="~common/images/index_case_ic.png" alt="" class="case-ic">
          <div class="title">啤酒品牌扫码活动</div>
          <div class="content">品牌通过采用数码通标准化营销玩法，区别于固化的扫码活动方式，通过“五维一体”实现营销“品效合一”的目的，摆脱内卷！</div>
          <div class="content">数码通升级版营销工具在传统的玩法上进行了全面升级：包括“商品互动区”，“电商引流区”以及“品牌信息区”。</div>
          <div class="content">•商品互动区保留传统的互动抽奖环节，内涵50+活动工具引擎，给消费者最新奇的玩法体验。同时新增每日的复购抽奖玩法“每日惊喜”，刺激消费者复购；增加特定节日活动：法定节日热点、电商节日促销、公司特殊日期回馈，多营销触点触达消费者。品牌采用经典大转盘形势，通过增加实物奖品提高奖品丰富度，提升消费者吸引力。</div>
          <div class="content">•电商引流区支持品牌进行VI、商品、卖点等植入，多点对产品进行曝光推荐，占领消费者心智。同时以首屏横幅、专题推荐、电商专区等为入口，与品牌直营或三方电商平台、优惠券体系等打通，吸引消费者直接跳转，形成引流、转化购买。</div>
          <div class="content">•品牌信息区支持品牌植入私域流量入口：如关注品牌公众号、视频号，跳转品牌自营APP等。为品牌私域流量阵地引流，随时唤醒、刺激复购。以节约70%的获客成本提高3倍的产品销售能力！同时，支持品牌招商信息、品牌故事等内容露出，打破市面上同类营销活动“主变现，轻品牌”的现状。</div>
          <div class="content">除此之外，消费者扫码后消费者扫码后的每条行为产生数据都将记录到企业的活动数据后台“数据驾驶舱”，形成消费者行为画像为品牌决策提供指导。全方位打通公域私域，连接内部外部数据，打通线上线下场景。</div>

          <img src="~common/images/index_case_ic.png" alt="" class="case-ic">
          <div class="title">摆脱营销内卷，实现营销品效合一</div>
          <div class="content">数字化转型的大浪潮下，一段时间以来我们看到快消众品牌们正纷纷觉醒，布局企业的数字化战略。</div>
          <div class="content">某啤酒品牌通过扫码活动，让产品成为线下真实消费者流量的触点和抓手，真正做到在开展营销活动的同时，为品牌及品牌产品矩阵提供更多曝光，实现“品效合一”。同时，二维码作为品牌线上私域入口，为品牌后续开展营销活动沉淀宝贵的数智资产，为品牌开展数字化战略打好地基。</div>
        </div>
      </div>
    </div>
    <p-footer></p-footer>
  </div>
</template>

<script type="text/ecmascript-6">
  import PHeader from 'components/header/header'
  import PFooter from 'components/footer/footer'

  export default {
    data() {
      return {
        detailIndex: 1
      }
    },
    created() {
    },
    activated() {
      this.detailIndex = this.$route.query.id
    },
    methods: {
    },
    watch: {},
    components: {
      PHeader,
      PFooter
    }
  }
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
  .dairy-wrapper
    width 100%
    min-width 1270px
    .dairy-container
      width 100%
      box-sizing border-box
      .index-container
        width 100%
        .bg-gray
          background #fbfcfd
        .detail-box
          background: #FFFFFF;
          padding-bottom: 0.47rem
          text-align: center
          width: 100%
          position: relative
          .detail-title
            font-size: 0.16rem;
            font-weight: 500;
            color: #191B31;
            letter-spacing: 1px;
          .case-ic
            width: 0.14rem
            height: 0.16rem
            position: relative
            top: 0.62rem
            left: 30.5%
            display: block
          .subtitle
            height: 0.31rem;
            margin-top: 0.47rem
            background: #F1FAFC;
            .subcontent
              margin-left: 0.25rem
              padding-left: 0.1rem
              width: 5.43rem;
              font-size: 0.15rem;
              font-weight: 500;
              color: #191B31;
              letter-spacing: 1px;
              border-left: 0.02rem solid #2356F7;
              position: relative
              left: 50%
              top: 50%
              transform: translate(-50%, -50%)
              text-align: left
          .title
            margin-top: 0.47rem
            font-size: 0.16rem;
            font-weight: 500;
            color: #191B31;
            line-height: 0.22rem;
            letter-spacing: 1px;
            margin-bottom: 0.2rem
          .content
            position: relative
            left: 50%
            transform: translateX(-50%)
            text-align: left
            width: 5.43rem;
            text-indent: 0.22rem
            font-size: 0.1rem;
            font-weight: 400;
            color: #191B31;
            line-height: 0.15rem;
            margin-top: 0.1rem
        .desc-box
          width: 100%
          font-size: 0.07rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 0.1rem;
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-top: 0.4rem
          padding-bottom: 0.5rem
          text-align: center;
          height: 0.47rem;
          .desc-img
            width: 0.35rem
            height: 0.35rem
            margin-top: 0.05rem
          .title
            margin-top: 0.12rem
            margin-left: 0.16rem
            display: inline;
          .line
            display: inline;
            width: 0.01rem;
            height: 0.42rem;
            background: #C6D0D8;
            margin: 0 0.16rem
        .bottom-box
          padding-bottom .47rem
        .check-box
          background: #F1FAFC;
          height: 0.54rem
          font-size: 0.16rem;
          font-weight: bold;
          color: #191B31;
          line-height: 0.54rem;
          padding-left: 2.21rem
          .title
            display: inline
            font-size: 0.16rem;
          .btn
            display: inline-block
            width: 0.84rem;
            height: 0.29rem;
            background: #FF9D44;
            border-radius: 0.04rem;
            font-size: 0.09rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 0.29rem;
            text-align: center
            margin-left: 1.8rem
</style>